@import '../../components/Global/Variables.scss';
@import '../../components/Global/Mixins.scss';

/*

// ==========================================================================
// Hero Styles
// ==========================================================================

*/

.hero {
  background-image: url('../../assets/images/large-hero--small.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  border-image: url('../../assets/images/large-hero__border.png') 0 0 7 fill;
  border-image-repeat: repeat repeat;
  border-width: 0 0 7px;
  border-style: solid;
  color: #fff;
  line-height: 1.6;
  font-size: 1.5rem;
  padding-bottom: 80px;
  position: relative;
  margin-top: 80px;

  @include tablet {
    background-image: url('../../assets/images/large-hero--medium.jpg');
  }

  @include desktop {
    background-image: url('../../assets/images/large-hero--large.jpg');
  }
}

@import '../Global/Variables.scss';
@import '../Global/Mixins.scss';

/*

// ==========================================================================
// Title Styles
// ==========================================================================

*/

.title {
	font-family: 'Homemade Apple', cursive;
	font-size: 1.35em;
    padding: 30px 0px 30px 0px;
    line-height: 2rem;

	@include tablet {
		font-size: 2.4em;
		padding: 40px;
    }
    
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 30px 0 30px 0;
        text-align: center;
    }

	&--light {
		font-family: 'Homemade Apple', cursive;
		font-size: 1.35em;
		padding: 30px 0px 30px 0px;
		color: #fff;
		text-align: center;

		@include tablet {
			font-size: 2.4em;
			padding: 40px;
		}
	}
}
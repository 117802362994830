@import '../../components/Global/Variables.scss';
@import '../../components/Global/Mixins.scss';

/*

// ==========================================================================
// Blog Styles
// ==========================================================================

*/

.blog {
    
    &__container {
        margin: 0 auto 4rem auto;
        max-width: $container-maxWidth;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;

        @include desktop {
            flex-direction: row;
            padding: initial;
        }
    }
}